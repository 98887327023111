/* Search Page */

.pageTitle {
  margin-top: 4rem;
}

.filtersContainer {
  margin-bottom: 2rem;
}

.searchFilters {
  display: flex;
  justify-content: flex-start;
  margin-left: 2rem;
  overflow-x: scroll;
}

.filterButton {
  font-weight: 600;
  margin: 0.5rem;
  border-radius: 1rem;
  padding: 2rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.12), 0 0 1px rgba(0, 0, 0, 0.05) inset;
  text-align: left;
}

.filterButton:hover{
  color: rgba(244, 201, 72, 255);
}

.errorMessage {
  margin-left: 2rem;
}


@media (min-width:960px) {
  .searchResults {}
}