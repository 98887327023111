/* General Style */
.App {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.appContainer {
  display: flex;
  flex-direction: column;
  min-height: 95vh;
  margin-bottom: 4rem;
}

.appHeader {
  width: 100%;
  background-color: rgb(255, 255, 255);
  height: 3rem;
}

h1,
h2 {
  margin: 2rem;
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.spinner {
  height: 25%;
  width: 25%;
}

/* Navigation */
.navbar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 4rem;
  background-color: rgb(248, 248, 255);
  display: flex;
  justify-content: space-between;
}

.navIcon {
  height: 2rem;
  width: 2rem;
  padding: 1rem;
}

/* Search Bar */
.searchForm {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 0.5rem;
  width: 100%;
}

.searchBar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.35rem;
  width: 65vw;
  margin-left: 2rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  border: 2px solid rgba(244, 201, 72, 255);
}

.searchButton {
  background-color: rgba(244, 201, 72, 255);
  border-color: rgba(244, 201, 72, 255);
  border-radius: 0.5rem;
  color: rgb(255, 255, 255);
  font-weight: 400;
  width: 2.75rem;
  height: 2.75rem;
}

.forwardArrow {
  width: 2rem;
  height: auto;
}

/* Recipes */

.recipeListsContainer {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  margin-right: 2rem;

}

.recipeListContainer {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: start;
  margin: 1rem 0;
}

.recipeTile {
  display: grid;
  grid-template-columns: 1fr;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.12), 0 0 1px rgba(0, 0, 0, 0.05) inset;
}

.recipeImage {
  width: 80vw;
}

.recipeListInfo {
  display: grid;
  grid-template-columns: 70vw 1fr;
  justify-items: start;
  width: 80vw;
  height: 15vh;
}

.recipeListInfo h3 {
  align-self: baseline;
  margin-top: 0;
  padding: 0.5rem;
}

.recipeLink {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.recipeLink:hover {
  color: rgba(244, 201, 72, 255);
}

.addButton {
  align-self: baseline;
  justify-self: flex-end;
  padding: 0.5rem;
}

/* Saved Recipes */

.savedRecipeImage {
  width: 80vw;
  height: auto;
  border-radius: 1rem;
  text-align: center;
}

.removeButton {
  align-self: baseline;
  justify-self: flex-end;
}

.userMessage {
  margin-left: 2rem;
}

.pageNotFoundContainer {
  display: flex;
  flex-direction: column;
  margin-top: 6rem;
}


/* Responsive Layout */
/* Tablet */
@media (min-width:600px) {

  .recipeListsContainer {
    flex-direction: row;
    width: 90vw;
    gap: 1.5rem;
    overflow: scroll;
  }

  .recipeListContainer {
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    max-width: 40vw;

  }

  .recipeImage,
  .savedRecipeImage {
    height: auto;
    width: 40vw;
  }

  .recipeListInfo {
    display: grid;
    grid-template-columns: 1fr 10vw;
    justify-items: start;
    width: 40vw;
  }
}

/* Desktop */
@media (min-width:960px) {
  .appMain {
    display: flex;
    flex-direction: column;
  }

  .recipeListsContainer {
    max-width: 100vw;
  }

  .recipeImage,
  .savedRecipeImage {
    height: auto;
    width: 30vw;
  }

  .recipeListInfo {
    display: grid;
    grid-template-columns: 1fr 10vw;
    justify-items: start;
    width: 30vw;
  }


}