.heroContainer {
    margin-top: 2rem;
}

.heroMessage {
    display: flex;
    flex-direction: column;
    margin: 0;
    align-items: center;
}

.heroTitle {
    margin: 0;
    font-weight: 600;
}

.heroDescription {
    margin: 0;
    text-align: center;
}

.heroImage {
    margin-top: 2rem;
    height: 100%;
    width: 100vw;
}

.heroImageTablet,
.heroImageDesktop {
    display: none;
}

.heroButton {
    background-color: rgba(244, 201, 72, 255);
    width: 17rem;
    padding: 0.5rem 0;
    border-radius: 1rem;

}

/* Responsive Layout */

/* Tablet */

@media (min-width:600px) {
    .heroContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
    }

    .heroImage {
        display: none;
    }

    .heroImageTablet {
        display: block;
        width: 100vw;
        height: 100%;
        margin-top: 2rem;
    }

    .heroMessage {
        font-size: 1.5rem;
    }
}




/* Desktop */
@media (min-width:960px) {

    .heroContent {
        display: flex;
    }

    .heroImageTablet {
        display: none;
    }

    .heroImageDesktop {
        display: block;
        width: 50%;
        height: 100%;
        margin-top: 2rem;
    }

    .heroMessage {
        align-items: flex-start;
    }

    .heroDescription {
        text-align: left;

    }
}