/* Recipe Card */
.recipeCardImage {
  width: 100vw;
  height: auto;
  margin: 1rem 0;
}

.recipeCardContent {
  display: flex;
  flex-direction: column;
}

.recipeCardIntro {
  display: flex;
  flex-direction: column;
}

.recipeCardInfo {
  display: flex;
  justify-content: space-between;
  align-items: baseline
}


.recipeCardTitle {
  justify-content: flex-start;
}

.recipeCardTitle h2 {
  margin-bottom: 0.5rem;
}

.buttonContainer {
  justify-content: flex-end;
  padding: 1rem;
}

.recipeCardDetails {
  margin-top: 0;
  margin-left: 2rem;
}


.recipeCardIngredients,
.recipeCardInstructions {
  margin: 0 1rem 1rem 1rem;
  border-radius: 1rem;
}

.ingredientsTitle,
.instructionsTitle {
  margin-left: 1.5rem;
}

.recipeCardIngredients {
  background-color: rgb(211, 211, 211, 0.5);

}

.recipeCardInstructions {
  background-color: rgba(244, 201, 72, 0.5);

}


.ingredientsList,
.instructionsList {
  margin: 1rem;


}


/* Responsive Layout */
/* Tablet */
@media (min-width:600px) {

  .recipeCardIntro {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin: 0 1rem;
    width: 90vw;
  }

  .recipeCardTitle {
    width: 45vw;
  }

  .recipeCardImage {
    width: 40vw;
  }



}